import useIsMobile from "../../hooks/responsive/use-is-mobile";
import backgroundImage from "../../assets/images/hero/portadaHome.jpg";
import mobileHero from "../../assets/images/hero/mobile-hero-bg2.svg"; // Not used in the component.
import backgroundImg from "../../assets/images/hero/yellow_background.png"; // Not used in the component.

export const TestimonialsFade = ({
  title,
  fullDescription,
  firstButtonTitle,
  secondButtonTitle,
}) => {
  const isMobile = useIsMobile();
  const styles = {
    headerInfo: {
      textAlign: isMobile ? "center" : "left", // 'text-center' & 'text-left' is not valid value for CSS property.
    },
    title: {
      color: isMobile ? "white" : undefined, // Use ternary to provide a fallback.
    },
    fullDescription: {
      color: isMobile ? "white" : undefined, // Use ternary to provide a fallback.
    },
    pageHeader: {
      backgroundImage: `url(${backgroundImage})`,
      backgroundPosition: isMobile ? "90% 50%" : undefined, // Use ternary to provide a fallback.
      backgroundSize: "cover",
      height: "100vh", // Adjusted to full viewport height.
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      position: "relative",
    },
    description: {
      marginBottom: "2em",
      fontSize: isMobile ? "1em" : "1.5em",
    },
    buttons: {
      display: isMobile ? "grid" : "flex",
      gap: isMobile ? undefined : "1em", // Use ternary to provide a fallback.
    },
    image: {
      display: isMobile ? "none" : "block", 
    },
  };

  return (
    <section className="gb-pink" style={styles.pageHeader}>
      <div className="page-header py-2 py-md-5 flex h-100 align-items-center">
        {isMobile && <span className="mask bg-gradient opacity-3"></span>} 
        <div className="container">
          <div className="row justify-content-center">
            <div
              className="col-md-6 m-auto flex-column d-flex"
              style={styles.headerInfo}
            >
              <h1  className="mb-4">
                {title}
              </h1>
             <p className={`${isMobile ? "text-lg" : "text-2xl"}`} style={{ textAlign: "left", color: "#000" }}>
                {fullDescription}
              </p>
              <div style={styles.buttons}>
                <a
                  className='btn btn-lg bg-purple text-white text-xl'
                  href="#talleres"
                >
                  {secondButtonTitle}
                </a>
                <a
                  className={
                    isMobile
                      ? `btn btn-lg bg-white`
                      : `btn btn-lg bg-yellow text-white text-xl`
                  }
                  href="/reserva"
                >
                  {firstButtonTitle}
                </a>
              </div>
            </div>
            <div style={styles.image} className="col-sm-6 text-center">
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialsFade;
