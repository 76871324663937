import useIsMobile from "../hooks/responsive/use-is-mobile";
import useGetCourses from "../hooks/data/use-get-courses";

import "../assets/scss/astro-ecommerce.scss";
import "../assets/animations/animations.css";

import Navbar from "../components/navbar";
import PromoSectionLarge from "../components/promo/promoSectionLarge";
import CardProduct from "../components/products/cardProduct";
import GroupProduct from "../components/products/groupProduct";
import PackageProduct from "../components/products/packageProduct";
import InfoImage from "../components/store/infoPicture";
import data from "../data/data.json";
import lanidngImage from "../assets/images/hero/shaped_background_landing.svg";
import packageBackgroundImage from "../assets/images/hero/shapes_quienes_somos_background.svg";
import teamBuildingImage1 from "../assets/images/hero/quienes_somos.png";
import teamBuildingImage2 from "../assets/images/hero/team_building_2.jpg";
import teamBuildingImage3 from "../assets/images/hero/team_building_3.jpg";
import aboutUsImage from "../assets/images/hero/home_sobre_nosotros.jpg";
import teamBuildingImage4 from "../assets/images/hero/team_building_4.jpg";
import groupProductImg from "../assets/images/hero/home_talleres_grupales.jpg";
import ourSpace1 from "../assets/images/nuevas/ESPACIO1.jpg"
import ourSpace2 from "../assets/images/nuevas/ESPACIO2.jpg"
import ourSpace3 from "../assets/images/nuevas/ESPACIO3.jpg"
import ourSpace4 from "../assets/images/nuevas/ESPACIO4.jpg"
import { Link } from "react-router-dom";
import InfoPictureForOurSpace from "../components/store/infoPictureForOurSpace";



export const Landing = () => {
  const heroImg = "https://muerdete-front-qa.s3.eu-north-1.amazonaws.com/imagenes_web/portada_web.jpg";
  const isMobile = useIsMobile();
  const courses = useGetCourses();

  const styles = {
    landingBackground: {
      backgroundImage: !isMobile && "url(" + lanidngImage + ")",
      backgroundSize: "cover",
    },
    packageBackground: {
      backgroundImage: !isMobile && "url(" + packageBackgroundImage + ")",
      backgroundSize: "cover",
    },
    section: {
      scrollSnapType: "y mandatory", // Aplicar scroll snap vertical
      height: "fit-content", // Altura para ocupar toda la pantalla
      color: "#ff5a88",
      position: "relative",
    },
  };


  return (
    <>
      <div className="page-container" style={{ position: "relative" }}>
        <Navbar />
        <PromoSectionLarge
          title={"¿Estás listo para morder la vida a bocados?"}
          fullDescription={
            "Únete a la revolución Muerdete. ¡Vamos a cocinar, reír y disfrutar como si no hubiera un mañana!"
          }
          firstButtonTitle={"Ya tengo un Bono"}
          secondButtonTitle={"Comprar Bono"}
          pageHeaderMinVh="80vh"
        />

        <div className="position-relative h-100">
          <section style={styles.section}>
            <div className="triangleBackgroundPink"></div>
            <div className="triangleBackgroundPink triangle-pink"></div>
            <div className="col-11 mx-auto my-7" id="talleres">
              <div className="triangle-yellow"></div>
              <div className="mb-5 mx-4 col-lg-6 col-sm-12">
                <h1>Nuestros talleres</h1>
                <p className="lead mb-sm-6 mb-4 text-2xl">
                  En Muerdete, te convertirás en un verdadero rockstar de la cocina. Nuestros talleres son una experiencia culinaria única, llena de diversión y sabores que desafiarán tus sentidos.
                </p>
              </div>
              <div id='talleres' className="row">
                {courses?.map((course) => (
                  !course.isPrivate && (
                    <div className="col-md-6 col-lg-4 mh-100 mb-4 mb-md-0 px-4" key={course.id}>
                      <CardProduct
                        course={course && course}
                        id={course.id}
                        thumb_src={course.thumbnail?.src}
                        header_src={course.images && course.images[0]}
                        thumb_alt={course.images && course.images[0]}
                        color={course.color}
                        title={course.title}
                        description={course.description}
                        price={course.price}
                        position="center"
                        image={course.images[0]}
                      />
                    </div>
                  )
                ))}

              </div>
            </div>
          </section>
        </div>
        <GroupProduct pageHeaderBgImg={groupProductImg} />
        <section id="teamBuilding" className="container py-5 py-lg-8">
          <InfoImage
            title="Talleres para empresas"
            subtitle={"Team building"}
            paragraphOne={
              "Si estás buscando una forma única y emocionante de fortalecer la cohesión de tu equipo o simplemente quieres disfrutar de un día divertido fuera de la oficina, Muerdete es tu elección ideal. ¡Contáctanos hoy mismo para personalizar tu taller a medida!"
            }
            paragraphTwo={
              "Tus empleados o compañeros de equipo se llevarán recuerdos deliciosos que perdurarán en el tiempo al finalizar la experiencia."
            }
            image1={teamBuildingImage1}
            image2={teamBuildingImage2}
            image3={teamBuildingImage3}
            image4={teamBuildingImage4}
          />
          <Link
            className=
            {
              isMobile
                ? `btn btn-white btn-lg btn-lg text-xl`
                : `btn btn-lg bg-purple text-white text-xl`
            }
            to="/team-building"
          >
            Más información
          </Link>
          <div className="triangle-yellow"></div>
        </section>
        <section id="nuestroEspacio" className="container py-5 py-lg-8">
          <InfoPictureForOurSpace
            title="Nuestro Espacio"
            paragraphOne={
              "¡Bienvenido a nuestro taller gastronómico! En Muérdete, te abrimos las puertas a la cocina donde tus habilidades culinarias toman vida. 🍴✨"
            }
            image1={ourSpace3}
            image2={ourSpace2}
            image3={ourSpace1}
            image4={ourSpace4}
          />
          <div className="triangle-yellow"></div>
        </section>
        <section style={styles.section}>
          <div className="col-10 mx-auto ">
            <h1 className="text-6xl">Packs de talleres</h1>
            <p className="lead mb-5 text-2xl">
              Consulta nuestros packs para grupos y personalizalos a tu medida.
            </p>
            <div className="row d-flex align-items-center h-100">
              {data.packages.map((product) => (
                <PackageProduct
                  thumb_src={product.thumb_src}
                  thumb_alt={product.thumb_alt}
                  title={product.title}
                  description={product.description}
                  discountTag={product.discountTag}
                  position="center"
                  minimumSpots={product.minimumSpots}
                  price={product.price}
                />
              ))}
            </div>
          </div>
        </section>
        <section style={styles.section} id="sobreNosotros">
          <InfoImage
            title={"Sobre nosotros"}
            paragraphTwo={
              "¡Hola! Somos dos locos amigos apasionados, con más de una década de experiencia en la emocionante escuela de talleres de cocina. En Muerdete, creemos que la cocina es mucho más que preparar alimentos; es una forma de conectar, aprender y disfrutar juntos. Nos encanta la gente, y nuestra misión es simple pero poderosa: hacer que todos disfruten de cada momento que pasan con nosotros. Creemos que la cocina es una puerta a la creatividad y la colaboración, y queremos abrir esa puerta para ti y tu equipo. Lo que nos diferencia es nuestra dedicación a la diversión y el aprendizaje. Nuestros talleres de cocina son mucho más que simples actividades; son experiencias culinarias llenas de desafíos, risas y memorias duraderas. Te retamos a superarte a ti mismo, pero siempre con una sonrisa."
            }
            image1={teamBuildingImage1}
            image2={teamBuildingImage2}
            image3={aboutUsImage}
            image4={teamBuildingImage4}
          />
        </section>
      </div>
    </>
  );
};

export default Landing;
