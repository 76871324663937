import { useEffect, useState } from "react";
import moment from "moment";
import "react-day-picker/dist/style.css";
import { DayPicker } from "react-day-picker";
import { Modal } from "antd";
import {
  ClockCircleOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import useGetLessons from "../../../hooks/data/use-get-lessons";
import { es } from 'date-fns/locale';
import { useLesson } from "../../../context/Provider";
import { useNavigate } from "react-router-dom";



const LessonCalendarForClients = ({
  course,
}) => {
  const lessons = useGetLessons();
  const [classes, setClasses] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState();
  const [daySelected, setDaySelected] = useState();
  const [daysAvailable, setDaysAvailable] = useState([]);
  const quantity = 1 ;
  const navigate = useNavigate()

  const { selectedLesson, setSelectedLesson } = useLesson();



  useEffect(() => {

      setClasses(lessons?.filter((lesson) => lesson?.courseId === course?.id));
  }, [lessons, course]);


  useEffect(() => {
    const filteredDates = classes
      ?.filter((cl) => {
        const lessonDate = new Date(cl?.lessonDate);
        const currentDate = new Date();
        const endDate = new Date();
        endDate.setDate(currentDate.getDate() + 90);

        return lessonDate >= currentDate && lessonDate <= endDate;
      })
      .map((cl) => new Date(cl?.lessonDate));

    setDaysAvailable(filteredDates);
  }, [classes]);

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  const GetIsDayDisabled = (day) => {
    return (
      daysAvailable?.find(
        (availableDate) => formatDate(day) === formatDate(availableDate)
      ) === undefined
    );
  };

  const setClassDate = (e) => {
    setIsModalOpen(true);
    setDaySelected(formatDate(e));
    setModalData(
      classes?.filter((cl) => formatDate(cl?.lessonDate) === formatDate(e))
    );
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onSelectClass = (e) => {
    setSelectedLesson(e)
    setIsModalOpen(false);
    const courseTitle = e.course.title?.split(' ').join('-').toLowerCase();
    navigate(`${courseTitle}/compra`)

  };



  return (
    <>
      <Modal
        title={`Horarios para el día ${daySelected}.`}
        open={isModalOpen}
        footer={false}
        onCancel={handleCancel}
      >
        {modalData && modalData.some(data =>
          data.seats > 0 &&
          data.seats >= quantity

        ) ? (
          <div className="d-flex gap-4 py-4 flex-wrap">
            {modalData
              .map(data => (
                <button
                  key={data.id}
                  onClick={() => onSelectClass(data)}
                  className={`w-100 btn shadow-xs border d-block text-center ${
                    data.seats === 0 ? 'btn-danger' : 'btn-success'
                  }`}
                >
                  <div className="d-flex gap-3 mb-2">
                    <ClockCircleOutlined className="my-auto" />
                    {data?.startHour}-{data?.finishHour}
                  </div>
                  <div className="d-flex gap-3">
                    <TeamOutlined className="my-auto" />
                    {data.seats} Plazas
                  </div>
                </button>
              ))}
          </div>
        ) : (
          <p>El taller disponible del día de la fecha no cuenta con capacidad disponible.</p>
        )}
      </Modal>
      <div
        className={"card w-100 p-4 mx-auto shadow-xs border bg-gray-100 col-lg-4"
        }
      >
          <div className="card shadow-xs border mt-2 bg-gray-100 flex align-items-center w-100" style={{ height: '100%' }}>
            <div className="card-body p-lg-4">
              <DayPicker
                locale={es}
                disabled={GetIsDayDisabled}
                mode="single"
                daySelected={daySelected}
                onSelect={setClassDate}
              />
            </div>
        </div>
      </div>
    </>
  );
};
export default LessonCalendarForClients;
